import React, { useEffect, useState } from 'react';
import { Table, PageHeader,Breadcrumb, Button,Form,Input,Pagination } from 'antd';
import { Link,useNavigate} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
import axios from 'axios';
const columns = [
  {
    title: 'ID',
    dataIndex: 'name',
    render:(text,record,index)=><Link to={`${record.id}`} >{text}</Link>,
  },
  {
    title: 'Название',
    dataIndex: 'lat',
    sorter: {
      compare: (a, b) => a.chinese - b.chinese,
      multiple: 3,
    },
  },
  {
    title: 'Адрес',
    dataIndex: 'updated_at',
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    sorter: {
      compare: (a, b) => a.english - b.english,
      multiple: 1,
    },
  },
];
const formItemLayout = {
  layout:'inline'
};

const Search=(props)=>(
  <Form
  name="validate_other"
  style={{
      margin:15,
      padding:10
  }}
  {...formItemLayout}
  onFinish={props.onFinish}
  > 
<Form.Item
  name="name"
  label="Название станции"
  hasFeedback
  rules={[{ required: true, message: 'Название станции' }]}
  >
  <Input placeholded="Название станции"/>
</Form.Item>
<Form.Item wrapperCol={{ span: 12, offset: 6 }}>
  <Button type="primary" htmlType="submit">
  Найти
  </Button>
</Form.Item>
</Form>
)

async function getStations(navigate){
  let data='',err='';
  try{
    data=await axios.get('api/station/chunk',{
      headers:{
        'Accept':'application/json',
      },
      withCredentials:true
    })
    .then(res=>res.data)
  }catch(error){
    if(error.response){
      if(error.response.status===401||error.response.status===419){
        navigate('../login');
      };
    }
    return error;
  }
  console.log(data);
  return {data,err};
}





const Stations=()=>{
  const [stations,setStations]=useState();
  const [pages,setPages] = useState(1);
  const [loadingStatus,setLoading] = useState(true);
  const [isError,setError] = useState(false);
  const [isSearch,setSearch] = useState(false);
  const [searchString,setSString] = useState();
  const navigate = useNavigate();
  useEffect(() => {   
      const fetchStation= async ()=>{
        const result = await getStations(navigate);
        setStations(result.data?.data);
        setPages(result.data?.total);
      };
       fetchStation();
    },[]);



  const onSearch=(name)=>{
    setSearch(true);
    setSString(name);
    axios.get(`api/station/find`,
    {
      params:{
        name
      },
      headers:{
        'Accept':'application/json'
      }
    }
  )
  .then(res=>{
    setStations(res.data.data);
    console.log(res.data.data);
    console.log(res.data.data);
    setPages(res.data.total);
    setSearch(true);
    setLoading(false);
    return res;
  })
  .catch(error=>{
    setLoading(false);
    setError(true);
    console.log(error);
    setPages(1);
    return error;
  });
  };
  const onPaginationChange=(page,name=null)=>{
    let params={}, path=`api/station/chunk?page=${page}`;
    if(isSearch){
      params={
        name
      };
      path=`api/station/find?page=${page}`;
    }
    let result = axios.get(
      path,
    {
      params,
      headers:{
        'Accept':'application/json',
        'Authorization':'Bearer secret'
      }
    }
  )
  .then(res=>{
    setStations(res.data.data);
    console.log(res.data.data);
    console.log(res.data);
    setPages(res.data.total);
    setLoading(false);
    return res;
  })
  .catch(error=>{
    setLoading(false);
    setError(true);
    setPages(1);
    return error;
  });
  }
   return ( 
     <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Главное</Breadcrumb.Item>
        <Breadcrumb.Item>Станции</Breadcrumb.Item>
      </Breadcrumb>
        <PageHeader
            className="site-page-header"
            onBack={() => null}
            title="Станции"
            extra={[
              <Button onClick={()=>navigate(`create`)} key="2" type="primary" icon={<PlusOutlined/>}>Добавить</Button>
            ]}
          />
          <Search onFinish={(val)=>onSearch(val.name)}/>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
      <Table  footer={()=><Pagination pageSize={30} onChange={(page)=>onPaginationChange(page,searchString)} total={pages/30} />} pagination={true} showSorterTooltip={false} columns={columns} dataSource={stations} />
      </div>
     </div>
     );
  
}



export default Stations;