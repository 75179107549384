import { Form, Input, Button,Layout ,message} from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router';
// import { useEffect } from 'react';
const {Content} = Layout;

  

const Login = () => {
    const navigate = useNavigate();
  const onFinish = (values) => {
    axios.get('sanctum/csrf-cookie',{withCredentials:true})
    .then( response=>{
        let result =  axios.post('api/login',
            {
                "email":values.email,
                "password":values.password,
            },
            {
                withCredentials:true,
                headers:{
                'Accept':'application/json',
            },
          })
            .then(res=>{
                navigate("../stations")})
            .catch(e=>e);
        return result;
    }
    
    );
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Ошибка");
  };

  // useEffect(()=>{
  //       axios.get('sanctum/csrf-cookie',{withCredentials:true})
  //       .then( response=>{
  //           console.log(response);
  //           let result =  axios.post('api/login',
  //               {
  //                   "email":"admin@admin.com",
  //                   "password":"12345678",
  //               },
  //               {
  //                   withCredentials:true,
  //                   headers:{
  //                   'Accept':'application/json',
  //               },
  //             })
  //               .then(res=>console.log(res))
  //               .catch(e=>e);
  //           return result;
  //       }
        
  //       );
  // },[])

  return (
      <Layout >
        <Content style={{margin:'auto',height:"100vh",display:"flex",justifyContent:'center',alignItems:"center",}}>
            <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            >
            <Form.Item
                label="Email"
                name="email"
                rules={[
                {
                    required: true,
                    message: 'Please input your email!',
                },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                {
                    required: true,
                    message: 'Please input your password!',
                },
                ]}
            >
                <Input.Password />
            </Form.Item>


            <Form.Item
                wrapperCol={{
                offset: 8,
                span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                Войти
                </Button>
            </Form.Item>
            </Form>
        </Content>
    </Layout>
  );
};

export default Login;