import React, { useState } from 'react';
// import { useNavigate } from 'react-router';
import { Upload, message, Row, Col,Form,Input, Button, InputNumber } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 15,
  },
  layout:"vertical"
};

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 0.30;
  if (!isLt2M) {
    message.error('Image must be smaller than 256KB!');
  }
  return isJpgOrPng && isLt2M;
}


const onFinish = async(values,imageUrl) => {
  //runs after calling submit
  if(imageUrl.length<2){
    message.warning('Значок должна быть загружена');
  }else{
    let response = await axios
    .post('api/station',
      {
        ...values,
        img_url:imageUrl
      },
      {
        headers:{
          'Accept':'application/json'
        }
      }
    )
    .then(res=>res)
    .catch(e=>e)
    if(response.status===200){
      message.success("Станция успешно создано");
    }else{
      message.error("Ошибка при создание");
    }
  }
};


const AddStation=()=>{
  // const navigation = useNavigate();
  const [loading,setLoading]=useState();
  const [imageUrl,setImgUrl]=useState("https://smartoil.asmgrup.com/logos/default.png");

    
    const handleChange = info => {
        if (info.file.status === 'uploading'){
          setLoading(true);
          return;
        }
        if (info.file.status === 'done'){
          setImgUrl(info.file.response.path);
          setLoading(false);
          return;
        }
        if(info.file.status === 'error'){
          if(!!info.file.response.message){
            message.error(info.file.response.message);
          }
          setLoading(false);
          return;
        }
      };

        const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Загрузить</div>
        </div>
        );
        return(
          <div style={{backgroundColor:"#fff",padding:10,margin:10}}>
       
          <Form {...layout} name="control-hooks" onFinish={values=>onFinish(values,imageUrl)}>
           
                <Form.Item label="Значок(120x120):">
                <Upload
                    accept="image/jpeg,image/png"
                    name="logo"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://smartoil.asmgrup.com/api/station/logo/create"
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    withCredentials
                    >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>

                </Form.Item>
                <Form.Item name="name" label="Название:" rules={[{required:true,message:'объязательное поля' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="address" label="Адрес:" >
                  <Input />
                </Form.Item>

                <Row wrap justify="start">
                  <Col flex={2} span={9}>
                    <Form.Item name="lon" label="longitude:" rules={[{required:true,message:'объязательное поля' }]}>
                      <InputNumber min={-180} max={180}/>
                    </Form.Item>
                  </Col>
                  <Col  span={9}>
                    <Form.Item name="lat" label="latitude:" rules={[{required:true,message:'объязательное поля' }]} >
                      <InputNumber min={-180} max={180}/>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item>
                <Button type="primary" htmlType="submit">
                  Создать
                </Button>
                </Form.Item>
              </Form>
          </div>
        )
}

export default AddStation;