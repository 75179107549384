import React, { useEffect, useState } from 'react';
import { Upload, message, Row, Col,Form,Input, Button, InputNumber } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useParams } from 'react-router';
import ErrorHandler from './layoutparts/ErrorHandler';
import SpinnnerContainer from './layoutparts/SpinnerContainer';


const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 15,
  },
  layout:"vertical"
};

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 0.30;
  if (!isLt2M) {
    message.error('Image must be smaller than 256KB!');
  }
  return isJpgOrPng && isLt2M;
}



const onFinish = async(values,imageUrl,stationId) => {
  //runs after calling submit

    let response = await axios
    .put(`api/station/${stationId}`,
      {
        ...values,
        img_url:imageUrl
      },
      {
        headers:{
          'Accept':'application/json'
        }
      }
    )
    .then(res=>res)
    .catch(e=>e)
    if(response.status===200){
      message.success("Станция успешно обновлено");
    }else{
      message.error("Ошибка при обновление");
    }
  
};


const  EditStation = ()=>{
  const [station,setStation] = useState({});
  const [loading,setLoading] = useState(false);
  const [imageUrl,setImgUrl]=useState("");
  const [isError,setError] = useState(false);
  const [loadingStatus,setLayoutLoading] = useState(true);
  const {stationId}=useParams();

    useEffect(()=>{
      axios.get(`api/station/${stationId}/edit`)
      .then((res)=>{
        setStation({...res.data});
        setImgUrl(res.data.img_url);
        setLayoutLoading(false);
        setError(false);
        console.log(res);
        return res;
      })
      .catch((error)=>{
        setError(true);
        setLayoutLoading(false);
        console.log(error);
        return error;
      })
    },[]);
    const handleChange = info => {
      if (info.file.status === 'uploading'){
        setLoading(true);
        return;
      }
      if (info.file.status === 'done'){
        setImgUrl(info.file.response.path);
        setLoading(false);
        return;
      }
      if(info.file.status === 'error'){
        if(!!info.file.response.message){
          message.error(info.file.response.message);
        }
        setLoading(false);
        return;
      }
    };
      
        const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Загрузить</div>
        </div>
        );
        return(
          <SpinnnerContainer loadingStatus={loadingStatus}>
          <ErrorHandler errorStaus={isError}>
            <div style={{backgroundColor:"#fff",padding:10,margin:10}}>
                <Form {...layout} name="control-hooks" initialValues={{...station}} onFinish={(val)=>onFinish(val,imageUrl,stationId)}>
                  <Form.Item label="Значок:">
                    <Upload
                        accept="image/jpeg,image/png"
                        name="logo"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://smartoil.asmgrup.com/api/station/logo/create"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        withCredentials
                        >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>

                  </Form.Item>
                  <Form.Item name="name" label="Название:"  >
                    <Input value={station.name} placeholder={station.name}/>
                  </Form.Item>
                  <Form.Item name="address" label="Адрес:" >
                    <Input value={station.address}  placeholder={station.address}/>
                  </Form.Item>

                  <Row wrap justify="start">
                    <Col flex={2} span={9}>
                      <Form.Item name="lon" label="longitude:"  >
                        <InputNumber min={-180} max={180} placeholder={station.lon} value={station.lon}/>
                      </Form.Item>
                    </Col>
                    <Col  span={9}>
                      <Form.Item name="lat" label="latitude:"  >
                        <InputNumber min={-180} max={180} placeholder={station.lat} value={station.lat}/>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item >
                  <Button type="primary" htmlType="submit">
                    Изменить
                  </Button>
                  </Form.Item>
                </Form>
            </div>
          </ErrorHandler>
          </SpinnnerContainer>
        )
    
}

export default EditStation;