import React, { useEffect, useState } from "react";
import { Table, message, Button, Form,  InputNumber } from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import SpinnnerContainer from "./SpinnerContainer";
import ErrorHandler from "./ErrorHandler";
import {  useNavigate, useParams } from "react-router";
import axios from "axios";



const unbind_manager=(id)=>{
    let result = axios.post('api/managers/unbind',
    {
        id
    },
    {
        headers:{
            'Accept':'application/json'
          }
    }   
)
    .then(res=>{
        message.success('Менеджер быль удалень');
        return res;
    })
    .catch(e=>{
        message.warning(e.message);
        console.log(e);
        return e;
    });
}

const formItemLayout = {
    layout:'inline'
  };

const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      
    },
    {
      title: 'user_id',
      dataIndex: 'user_id',
    },
    {
        title: 'Id',
        dataIndex: 'id',
    },
    {
      render:(text,record,index)=><Button danger onClick={()=>unbind_manager(record.id)} type="primary" icon={<DeleteOutlined/>}></Button>
    }
  ];

const onFinish=(values,stationId,navigate)=>{
    let result = axios.post('api/managers/bind',
        {
            stationId:stationId,
            user_id:values.user_id,
        },
        {
            headers:{
                'Accept':'application/json'
              }
        }   
    )
        .then(res=>{
            message.success('Добавлень');
            return res;
        })
        .catch(e=>{
            message.warning(e.message);
            console.log(e);
            return e;
        });
}

const StationPetrolTable=()=>{
    const [managers,setManager] = useState();
    const [loadingStatus,setLoading] = useState();
    const [isError,setError] = useState(false);
    const {stationId} = useParams();
    const [available_petrol,setAvbPetrol] = useState([]);
    const navigate = useNavigate();
    useEffect(()=>{
        axios.get(`api/managers/parent/${stationId}`)
        .then(res=>{
            setManager(res.data);
            return res;
        })
        .catch((e)=>{
            setLoading(false);
            setError(true);
            return e;
        });
    },[]);

    return(
        <div>
            <Form
                name="validate_other"
                {...formItemLayout}
                onFinish={(val)=>onFinish(val,stationId,navigate)}
                style={{
                    margin:15,
                    padding:10
                }}
                > 
            <Form.Item
                name="user_id"
                label="ID менеджера"
                hasFeedback
                rules={[{ required: true, message: 'Добавьте ID менеджера' }]}
                >
                <InputNumber  min={1}/>
            </Form.Item>
            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Button type="primary" htmlType="submit">
                Добавить
                </Button>
             </Form.Item>
          </Form>
            <SpinnnerContainer loadingStatus={loadingStatus}>
                <ErrorHandler errorStatus={isError} status={404} message="Ничего не было найдено">
                    <Table showSorterTooltip={false} pagination={true} columns={columns} dataSource={managers} />
                </ErrorHandler>
            </SpinnnerContainer>
        </div>
            )
}


export default StationPetrolTable;