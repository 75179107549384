import React, { useEffect,useState } from 'react';
import { Table, PageHeader,Breadcrumb, Button, Pagination,Form,Input, message } from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SpinnnerContainer from './layoutparts/SpinnerContainer';
import ErrorHandler from './layoutparts/ErrorHandler';

const formItemLayout = {
  layout:'inline'
};


const Search=(props)=>(
  <Form
  name="validate_other"
  style={{
      margin:15,
      padding:10
  }}
  {...formItemLayout}
  onFinish={props.onFinish}
  > 
<Form.Item
  name="name"
  label="Имя менеджера"
  hasFeedback
  rules={[{ required: true, message: 'Добавьте ID менеджера' }]}
  >
  <Input placeholded="Имя менеджера"/>
</Form.Item>
<Form.Item wrapperCol={{ span: 12, offset: 6 }}>
  <Button type="primary" htmlType="submit">
  Найти
  </Button>
</Form.Item>
</Form>
)



const Managers=()=>{
  const navigate = useNavigate();
  const [managers,setManagers] = useState();
  const [loadingStatus,setLoading] = useState(true);
  const [isError,setError] = useState(false);
  const [pages,setPages] = useState(1);
  const [isSearch,setSearch] = useState(false);
  const [searchString,setSString] = useState();
  useEffect(()=>{
    axios.get('api/managers/chunk',
      {
        headers:{
          'Accept':'application/json',
          'Authorization':'Bearer secret'
        }
      }
    )
    .then(res=>{
      if(res.status===401||res.status===419){
        navigate('../login');
      };
      setManagers(res.data.data);
      console.log(res.data.data);
      console.log(res.data);
      setPages(res.data.total);
      setLoading(false);
      return res;
    })
    .catch(error=>{
      if(error.response){
        if(error.response.status===401||error.response.status===419){
          navigate('../login');
        };
      }
      setLoading(false);
      setError(true);
      setPages(1);
      return error;
    });
  },[]);

  const onSearch=(name)=>{
    setSearch(true);
    setSString(name);
    axios.get(`api/managers/find`,
    {
      params:{
        name
      },
      headers:{
        'Accept':'application/json'
      }
    }
  )
  .then(res=>{
    setManagers(res.data.data);
    console.log(res.data.data);
    console.log(res.data.data);
    setPages(res.data.total);
    setSearch(true);
    setLoading(false);
    return res;
  })
  .catch(error=>{
    setLoading(false);
    setError(true);
    console.log(error);
    setPages(1);
    return error;
  });
  };
  const onPaginationChange=(page,name=null)=>{
    let params={}, path=`api/managers/chunk?page=${page}`;
    if(isSearch){
      params={
        name
      };
      path=`api/managers/find?page=${page}`;
    }
    let result = axios.get(
      path,
    {
      params,
      headers:{
        'Accept':'application/json',
        'Authorization':'Bearer secret'
      }
    }
  )
  .then(res=>{
    setManagers(res.data.data);
    console.log(res.data.data);
    console.log(res.data);
    setPages(res.data.total);
    setLoading(false);
    return res;
  })
  .catch(error=>{
    setLoading(false);
    setError(true);
    setPages(1);
    return error;
  });
  }

  const destroy_manager=(id)=>{
    let result = axios.delete(`api/managers/${id}`,
    {
        headers:{
            'Accept':'application/json'
          }
    }   
    )
    .then(res=>{
        message.success('Менеджер быль удалень');
        return res;
    })
    .catch(e=>{
        message.warning(e.message);
        console.log(e);
        return e;
    });
}
  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name',
    },
    {
      title: 'telegram_id',
      dataIndex: 'user_id',
    },
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Станция',
      dataIndex: 'station_name',
    },
    {
      render:(text,record,index)=><Button danger onClick={()=>destroy_manager(record.id)} type="primary" icon={<DeleteOutlined/>}></Button>
    }
    // {
    //   title: 'Изменить',
    //   dataIndex: 'change',
    //   render:(text,record,index)=><Link to={`${record.id}/edit`} >Изменить</Link>
    // }
  ];

   return ( 
     <SpinnnerContainer loadingStatus={loadingStatus}>
        <ErrorHandler errorStatus={isError}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Главное</Breadcrumb.Item>
            <Breadcrumb.Item>Менеджеры</Breadcrumb.Item>
          </Breadcrumb>

          <PageHeader
              className="site-page-header"
              onBack={() => navigate(-1)}
              title="Менеджеры"
              
            />
          <Search onFinish={(val)=>onSearch(val.name)}/>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Table  footer={()=><Pagination pageSize={30} onChange={(page)=>onPaginationChange(page,searchString)} total={pages/30} />} pagination={true} showSorterTooltip={false} columns={columns} dataSource={managers} />
          </div>
        </ErrorHandler>
      </SpinnnerContainer>
     );
}



export default Managers;