import React, { useEffect,useState } from "react";
import {Form, Input, Button, message } from 'antd'
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import ErrorHandler from "./layoutparts/ErrorHandler";
import SpinnnerContainer from "./layoutparts/SpinnerContainer";

const form_layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 15,
    },
    layout:"vertical"
  };
  const onFinish = async(values,navigation,id) => {
    //runs after calling submit
      let response = await axios
      .put(`api/petroldefaults/${id}`,
        {
          type:values.type
        },
        {
          headers:{
            'Accept':'application/json'
          }
        }
      )
      .then(res=>res)
      .catch(e=>e)
      if(response.status===200){
        navigation('../petrols');
      }else{
        console.log(response);
        message.error("Ошибка при обновление");
      }
    
};
  

const EditPetrol=()=>{
    let navigation = useNavigate();
    let {petrolId}=useParams();
    const [petrol,setPetrol] = useState({});
    const [isError,setError] = useState(false);
    const [loadingStatus,setLayoutLoading] = useState(true);
    useEffect(()=>{
      axios.get(`api/petrols/${petrolId}/edit`)
      .then((res)=>{
        setPetrol({...res.data});
        setLayoutLoading(false);
        setError(false);
        return res;
      })
      .catch((error)=>{
        setError(true);
        setLayoutLoading(false);
        return error;
      })
    },[]);

    return(
      <SpinnnerContainer loadingStatus={loadingStatus}>
      <ErrorHandler errorStaus={isError}>
          <div style={{backgroundColor:'#fff',padding:10,margin:10}}>
              <Form {...form_layout} initialValues={{...petrol}} onFinish={(val)=>onFinish(val,navigation,petrolId)}>
                  <Form.Item name="type" label="Название:" rules={[{ required: true }]}>
                      <Input />
                  </Form.Item>
                  <Form.Item >
                  <Button type="primary" htmlType="submit">
                    Обновить
                  </Button>
                  </Form.Item>
              </Form>
          </div>
      </ErrorHandler>
      </SpinnnerContainer>
    )
}

export default EditPetrol;