import React, { useEffect, useState } from "react";
import { Table, message, Button, Select,Form } from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import SpinnnerContainer from "./SpinnerContainer";
import ErrorHandler from "./ErrorHandler";
import { Navigate, useNavigate, useParams } from "react-router";
import axios from "axios";


const { Option } = Select;

const formItemLayout = {
    layout:'inline'
  };

const columns = [
    {
      title: 'Название',
      dataIndex: 'type',
      
    },
    {
      title: 'Поставшик',
      dataIndex: 'supplier',
    },
    {
        title: 'Стоимость',
        dataIndex: 'price',
    },
    {
        title: 'Статус',
        dataIndex: 'is_available',
    },
    {
      render:(text,record,index)=><Button danger type="primary" icon={<DeleteOutlined/>}></Button>
    }
  ];

const onFinish=(values,stationId,navigate)=>{
    let result = axios.post('api/petrols',
        {
            fuel_station_id:stationId,
            type:values.type,
            supplier:values.suplier
        },
        {
            headers:{
                'Accept':'application/json'
              }
        }   
    )
        .then(res=>{
            message.success('Добавлень');
            return res;
        })
        .catch(e=>{
            message.warning('Такой видь уже имеется');
            console.log(e);
            return e;
        });
}

const StationPetrolTable=()=>{
    const [petrol,setPetrol] = useState();
    const [loadingStatus,setLoading] = useState();
    const [isError,setError] = useState(false);
    const {stationId} = useParams();
    const [available_petrol,setAvbPetrol] = useState([]);
    const navigate = useNavigate();
    useEffect(()=>{
        axios.get(`api/petrols/parent/${stationId}`)
        .then(res=>{
            setPetrol(res.data);
            return res;
        })
        .catch((e)=>{
            setLoading(false);
            setError(true);
            return e;
        });
    },[]);

    const onEnter=()=>{
        if(available_petrol.length<=1){
            setLoading(true);
            let result = axios.get('api/petroldefaults',
            {
                headers:{
                    'Accept':'application/json'
                  }
            })
            .then(res=>{
            setAvbPetrol(res.data);
            setLoading(false);  
            return res;
            })
            .catch(e=>{
            setLoading(false);
            return e;
            }); 
        }else{
            return;
        }
    }
    return(
        <div>
            <Form
                name="validate_other"
                {...formItemLayout}
                onFinish={(val)=>onFinish(val,stationId,navigate)}
                style={{
                    margin:15,
                    padding:10
                }}
                > 
            <Form.Item
                name="type"
                label="Вид топливы"
                hasFeedback
                rules={[{ required: true, message: 'Выберите вид топливы' }]}
                >
                <Select onClick={onEnter} placeholder="Выберите вид топливы">
                    {available_petrol.map((elem,i)=>(
                        <Option value={elem.type} key={i}>{elem.type}</Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                <Button type="primary" htmlType="submit">
                Добавить
                </Button>
             </Form.Item>
          </Form>
            <SpinnnerContainer loadingStatus={loadingStatus}>
                <ErrorHandler errorStatus={isError} status={404} message="Ничего не было найдено">
                    <Table showSorterTooltip={false} pagination={true} columns={columns} dataSource={petrol} />
                </ErrorHandler>
            </SpinnnerContainer>
        </div>
            )
}


export default StationPetrolTable;