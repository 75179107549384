import React, { useEffect ,useState} from 'react';
import { Table, PageHeader,Breadcrumb, Button } from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import { useNavigate,Link } from 'react-router-dom';
import axios from 'axios';






const Petrols=()=>{
  const navigate = useNavigate();
  const [petrols,setPetrols] = useState();
  useEffect(()=>{
    let result = axios.get('api/petroldefaults',
      {
        headers:{
          'Accept':'application/json'
        }
      }
    )
    .then(res=>{
      if(res.status===401||res.status===419){
        navigate('../login');
      };
      setPetrols(res.data);
      return res;
    })
    .catch(error=>{
      if(error.response){
        if(error.response.status===401||error.response.status===419){
          navigate('../login');
        };
      }
      return error;
    });
  },[]);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'type',
    },
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Изменить',
      dataIndex: 'change',
      render:(text,record,index)=><Link to={`${record.id}/edit`} >Изменить</Link>
    }
  ];

   return ( 
     <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Главное</Breadcrumb.Item>
        <Breadcrumb.Item>Топлива</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
          className="site-page-header"
          onBack={() => null}
          title="Топливы"
          extra={[
            <Button key="2" onClick={()=>navigate('create')} type="primary" icon={<PlusOutlined/>}  >Добавить</Button>
          ]}
        />
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Table  showSorterTooltip={false} columns={columns} dataSource={petrols} />
      </div>
     </div>
     );
}



export default Petrols;