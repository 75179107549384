import {Layout, Spin } from "antd";
import React from "react";


const {Content} = Layout;

const SpinnnerContainer=(props)=>{
    return(
        props.loadingStatus?
        <Content style={{
            display:'flex',
            justifyContent:'center',
            alignContent:'center',
            height:'100%',
            position: 'relative',
        }}>
            <Spin size='large'  style={{
                margin:'0 auto',
                position: 'absolute',
                top: '50%'
            }}/>
        </Content>:
        props.children
    );
}

export default SpinnnerContainer;