import React from 'react';
import { Route, Routes } from 'react-router';
import Petrols from './components/Petrols';
import Stations from './components/Stations';
import Main from './components/Main';
import StationSingle from './components/StationSingle';
import AddStation from './components/AddStation';
import EditStation from './components/EditStation';
import AddPetrol from './components/AddPetrol';
import EditPetrol from './components/EditPetrol';
import Managers from './components/Managers';
// import Login from './components/auth/Login';
import 'antd/dist/antd.css';
import './App.css'
import  axios  from 'axios';
import Login from './components/auth/Login';

axios.defaults.withCredentials=true;
axios.defaults.baseURL='https://smartoil.asmgrup.com/';

class App extends React.Component {
  render() {
    const props=this.props;
    window.pro=props;
    console.log(props);
    return (
      <Main>
        <Routes>
          <Route path="login" element={<Login/>}/>
          <Route path="/" element={<Stations/>}/>
          <Route path="stations" element={<Stations/>}/>
          <Route path="stations/:stationId" element={<StationSingle/>}/>
          <Route path="stations/create" element={<AddStation/>}/>
          <Route path="stations/:stationId/edit" element={<EditStation/>}/>
          <Route path="petrols" element={<Petrols/>}/>
          <Route path="petrols/:petrolId/edit" element={<EditPetrol/>}/>
          <Route path="petrols/create" element={<AddPetrol/>}/>
          <Route path="managers" element={<Managers/>}/>
        </Routes>
      </Main>
    );
  }
}

export default App;