import React from "react";
import {Form, Input, Button, message } from 'antd'
import axios from "axios";
import { useNavigate } from "react-router";

const form_layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 15,
    },
    layout:"vertical"
  };

const onFinish = async(values,navigation) => {
    //runs after calling submit
      let response = await axios
      .post('api/petroldefaults',
        {
          type:values.type
        },
        {
          headers:{
            'Accept':'application/json'
          }
        }
      )
      .then(res=>res)
      .catch(e=>e)
      if(response.status===200){
        navigation('../petrols');
      }else{
        console.log(response);
        message.error("Ошибка при создание");
      }
    
  };
  
const AddPetrol=()=>{
    const navigation  = useNavigate();
    return(
        <div style={{backgroundColor:'#fff',padding:10,margin:10}}>
            <Form {...form_layout} onFinish={val=>onFinish(val,navigation)}>
                <Form.Item name="type" label="Название:" rules={[{ required: true }]}>
                    <Input/>
                </Form.Item>
                <Form.Item >
                <Button type="primary" htmlType="submit">
                  Создать
                </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default AddPetrol;