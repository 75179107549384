import React, { useEffect, useState } from "react";
import { PageHeader, Tabs, Button, Statistic, Descriptions,Tag,Table,Modal, message } from 'antd';
import {EditOutlined, DeleteOutlined, EyeInvisibleOutlined,EyeOutlined} from '@ant-design/icons';
import {useNavigate, useParams} from 'react-router-dom';
import axios from "axios";
import ErrorHandler from "./layoutparts/ErrorHandler";
import SpinnnerContainer from "./layoutparts/SpinnerContainer";
import StationPetrolTable from "./layoutparts/StationPetrolTable";
import StationManagerTable from "./layoutparts/StationManagerTable";
const { TabPane } = Tabs;


const warning=(station_id,navigate)=>{
    const deleteStation=async()=>{
      let result =await axios.delete(`api/station/${station_id}`)
      .then(res=>res)
      .catch(e=>e);
      if(!!result.data){
        navigate('../stations',{replace:true});
      }else{
        message.error('Ошибка при удалении');
      }
      console.log(result);
      // message.info(result.)
    };
    Modal.warning({
      title: 'Полное удаление станции',
      content: 'этот процесс не возвратимо,если хотити можно просто изменить видимость станции!',
      okText:'Принять',
      cancelText:'Отмена',
      closable:true,
      maskClosable:true,
      onOk:()=>deleteStation(station_id),
      centered:true
    });
}
const changeVisibility=async (station_id,status)=>{
  let statusNumRep = status?1:0;
  let response = await axios
    .get(`api/station/${station_id}/status/${parseInt(statusNumRep)}`,
      {
        headers:{
          'Accept':'application/json'
        }
      }
    )
    .then(res=>res)
    .catch(e=>e)
    if(response.status===200){
      message.success("Видимость изменено");
      // console.log(response);
    }else{
      // console.log(response);
       message.error("Ошибка при изменении, повторити попитку позже");
    }
};
const renderContent = (address="") => (
  <Descriptions size="default" column={2}>
    <Descriptions.Item  label="Адрес">{address?address:"Адрес не задано" }</Descriptions.Item>
    <Descriptions.Item label="Гео локация"><a>Google Map</a></Descriptions.Item>
  </Descriptions>
);
const ExtraContent =(props)=> (
  <div
    style={{
      display: 'flex',
      width: 'max-content',
      justifyContent: 'flex-end',
    }}
  >
    <Statistic
      title="Создано"
      value={props.created}
      style={{
        marginRight: 32,
      }}
    />
    <Statistic title="Изменено" value={props.updated} />
  </div>
);

const Content = ({ children, extra }) => (
  <div className="content">
    <div className="main">{children}</div>
    <div className="extra">{extra}</div>
  </div>
);


const StationSingle=()=>{
  const navigate = useNavigate();
  const [station,setStation] = useState({});
  const [isError,setError] = useState(false);
  const [loadingStatus,setLoading] = useState(true);
  let {stationId} = useParams();
  let [status,setStatus] = useState(1);

  useEffect(()=>{ 
    axios.get(`api/station/single/${stationId}`)
    .then(response=>{
      setStation({...response.data});
      setStatus(response.data.status);
      setLoading(false);
      setError(false);
      console.log(response);
      return response;
    })
    .catch(error=>{
      setError(true);
      setLoading(false);
      return error;
    });
  },[]);
        return(
          <SpinnnerContainer loadingStatus={loadingStatus}>
            <ErrorHandler errorStaus={isError}>
                <PageHeader
                className="site-page-header-responsive"
                // onBack={() => window.history.back()}
                avatar={{src:station.img_url,size:'large'}}
                tags={<Tag color="green">активный</Tag>}
                title={station.name}
                extra={[
                  <Button key="2" onClick={()=>{changeVisibility(stationId,!status);setStatus(!status)}} icon={status?<EyeInvisibleOutlined/>:<EyeOutlined/>}  >{status?'Скрыть':'Показать'}</Button>,
                  <Button key="3" onClick={()=>navigate('edit')} icon={<EditOutlined/>} type="primary">Изменить</Button>,
                  <Button key="1" onClick={()=>warning(stationId,navigate)} icon={<DeleteOutlined/>} danger type="primary">Удалить</Button>,
                ]}
                footer={
                  <Tabs size="large" type="card"  defaultActiveKey="1">
                    <TabPane tab="Топлива" key="1" >
                        <StationPetrolTable/>
                    </TabPane>
                    <TabPane tab="Менеджеры" key="2" >
                        <StationManagerTable/>
                    </TabPane>
                  </Tabs>
                }
              >
                <Content extra={<ExtraContent created={station.created_at} updated={station.updated_at}/>}>{renderContent(station.address)}</Content>
              </PageHeader>
            </ErrorHandler>
          </SpinnnerContainer>
        )
}

export default StationSingle;