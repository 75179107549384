import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
  EnvironmentOutlined,
  PieChartOutlined,
  BgColorsOutlined,
  TeamOutlined,
} from '@ant-design/icons';


const { Header, Content, Footer, Sider } = Layout;


class Main extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  render() {
    const { collapsed } = this.state;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider breakpoint="lg"
        collapsedWidth="0"  collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
          <div className="logo" />
          <Menu theme="dark"  mode="inline">
            <Menu.Item key="1" icon={<PieChartOutlined />}>
                <Link to="/">
                  Главное
                </Link>
              </Menu.Item>
            <Menu.Item key="2" icon={<EnvironmentOutlined />}>
                <Link to="/stations">
                    Станции
                </Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<BgColorsOutlined />}>
                <Link to="/petrols">
                    Топливы
                </Link>              
            </Menu.Item>
            <Menu.Item key="4" icon={<TeamOutlined />}>
                <Link to="/managers">
                    Менеджеры
                </Link>              
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Content style={{ margin: '0 16px' }}>
             {this.props.children}
          </Content>
          <Footer style={{ textAlign: 'center' }}>Smart OIL 2021</Footer>
        </Layout>
      </Layout>
    );
  }
}

export default Main;
