import { Button, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router";




const ErrorHandler=(props)=>{
    const navigate = useNavigate();
    return(
        props.errorStatus?
        <Result
        status={props.status?props.status:'warning'}
        title={props.message?props.message:"Что то пошло не так."}
        extra={<Button onClick={()=>navigate('/',{replace:true})} type="primary">Назад</Button>}
         />:
        props.children
    );
}

export default ErrorHandler;